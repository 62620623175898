<template>
  <mobile-screen :header="true" screen-class="profile-screen icon-app1 gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("profile.profile.delegate") }}
        </div>
        <template v-slot:right>
          <button @click="search = !search">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="searchQuery = ''"
        :simpleSearch="true"
      ></search>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul class="clebex-item-section pill" v-if="listOfDelegateUsers">
      <li
        class="clebex-item-section-item"
        v-for="user in listOfDelegateUsers"
        :key="user.id"
      >
        <router-link
          :to="{
            name: 'r_profile-my-delegates-add-permission',
            params: { userId: user.id }
          }"
          class="clebex-item-content-wrapper"
        >
          <div class="clebex-section-input">
            <label class="clebex-section-input-label cursor-pointer"
              >{{ user.first_name }} {{ user.last_name }}
            </label>
            <label class="clebex-section-input-label cursor-pointer"
              ><span>{{ user.email }}</span>
            </label>
          </div>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                v-if="
                  $route.params &&
                    $route.params.userId &&
                    $route.params.userId == user.id
                "
                icon="#cx-app1-checkmark"
              />
              <icon
                v-else
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              />
            </span>
          </span>
        </router-link>
      </li>
    </ul>
    <template v-slot:footer> </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "ProfileMyDelegatesAdd",
  mixins: [paginationMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  data() {
    return {
      searchQuery: "",
      search: false
    };
  },
  created() {
    this.getDelegateUsers();
  },
  computed: {
    ...mapState("delegates", ["delegateUsers"]),
    listOfDelegateUsers() {
      const query = this.searchQuery;
      if (this.delegateUsers && this.delegateUsers.length) {
        if (query && query.length > 1) {
          return this.delegateUsers.filter(item =>
            `${item.first_name} ${item.last_name} ${item.email}`
              .toLowerCase()
              .includes(query.toLowerCase())
          );
        } else {
          return this.delegateUsers;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("delegates", ["getDelegateUsers"]),
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
